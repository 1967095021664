import { Link } from "@inertiajs/react";
import SmallLogo from "./ui/SmallLogo";
import { Button } from "@/Components/ui/Button";
import { t } from "i18next";
import {
    DarkFacebook,
    Instagram,
    LinkedIn,
    Tiktok,
    Twitter,
    TwitterBird,
    WhatsApp,
    Youtube,
} from "@/Components/ui/Icons";
import NavbarLink from "@/Components/ui/NavLink";
import { PublicLinksTranslation, publicLinks } from "@/lib/helpers/nav-links";
import MakaremLogo from "./ui/MakaremLogo";
import { Mail } from "lucide-react";
import CopyRight from "./CopyRight";

type FooterProps = {
    reversed?: boolean;
};

export default function Footer({ reversed = false }: FooterProps) {
    return (
        <>
            <footer
                className={`footer lg:pt-16 bg-white ${
                    reversed ? "lg:flex lg:flex-row-reverse" : ""
                }`}
            >
                <div className=" px-9 lg:px-28">
                    <span className="flex flex-col lg:flex-row lg:justify-between lg:gap-0 py-14 lg:py-0 lg:pb-16 ">
                        <div className="flex flex-col gap-10 lg:flex-row lg:justify-between lg:gap-32 ">
                            <div className="flex items-center gap-4 lg:pb-0">
                                <Link href={"/"}>
                                    <SmallLogo />
                                </Link>
                                <MakaremLogo />
                            </div>

                            <div className="flex flex-col gap-2">
                                <NavbarLink href={"/courses"} className="">
                                    {t("links.courses")}
                                </NavbarLink>
                                <NavbarLink href={"/login"} className="">
                                    {t("auth.login")}
                                </NavbarLink>
                                <NavbarLink href={"/register"} className="">
                                    {t("auth.register")}
                                </NavbarLink>
                            </div>
                            <div className="flex flex-col gap-2">
                                {/* <NavbarLink href={"/team"} className="">
                                    {t("links.team")}
                                </NavbarLink> */}
                                <NavbarLink href={"/contact"} className="">
                                    {t("links.contactUs")}
                                </NavbarLink>
                                <NavbarLink href={"/about"} className="">
                                    {t("links.aboutUs")}
                                </NavbarLink>
                            </div>
                        </div>

                        <div className="flex flex-col items-center gap-5 pt-7 lg:pt-0">
                            <div className="flex flex-col gap-3">
                                <Link href="/contact">
                                    <p className="text-lg font-semibold hover:underline">
                                        {t("app.followUs")}
                                    </p>
                                </Link>
                            </div>
                            <div className="flex items-center gap-6 text-primary">
                                <a href="https://wa.me/966555995386">
                                    <WhatsApp className="w-6 fill-primary" />
                                </a>
                                <a href="mailto:Courses@makarem.org.sa">
                                    <Mail
                                        height={"32"}
                                        width={"32"}
                                        className="fill-primary stroke-white"
                                    />
                                </a>
                                <a
                                    href={
                                        "https://youtube.com/@makarem_ksa?si=kBYxb4zFofnoR0sp"
                                    }
                                >
                                    <Youtube fill="dark" />
                                </a>
                                <a href={"https://x.com/makarem_ksa?s=21"}>
                                    <Twitter className="w-full h-full" />
                                </a>
                                <a
                                    href={
                                        "https://www.instagram.com/makarem_ksa?igsh=NTh1Z2t2NDRraHNy"
                                    }
                                >
                                    <Instagram />
                                </a>
                                <a
                                    href={
                                        "https://www.linkedin.com/company/makarem/"
                                    }
                                >
                                    <LinkedIn />
                                </a>
                            </div>
                        </div>
                    </span>
                </div>
                <CopyRight />
            </footer>
        </>
    );
}
