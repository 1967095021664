// SiteHeader.tsx
import MainNav from "@/Components/MainNav";
import MobileNav from "@/Components/MobileNav";

export default function SiteHeader() {
    return (
        <header className="w-full">
            <div className="items-center m-4 h-14">
                <MainNav />
                <MobileNav role="public" />
            </div>
        </header>
    );
}
