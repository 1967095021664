import { t } from "i18next";
import { Link } from "@inertiajs/react";

export default function CopyRight() {
    return (
        <div className="flex justify-center px-4 py-6 border-t  bg-white">
            <p className="text-xs text-center text-foreground/80 sm:text-base">
                {t("app.copyright")} |
                <Link className="text-primary" href="/terms">
                    {t("app.termsAndLaws")}
                </Link>
                |
                <Link className="text-primary" href="/policy">
                    {t("app.privacyPolicy")}
                </Link>
            </p>
        </div>
    );
}
