// MainNav.tsx
import { Button } from "@/Components/ui/Button";
import { t } from "i18next";
import SmallLogo from "@/Components/ui//SmallLogo";
import { Link, usePage } from "@inertiajs/react";
import NavbarLink from "@/Components/ui/NavLink";
import { PublicLinksTranslation, publicLinks } from "@/lib/helpers/nav-links";
import { cn } from "@/lib/utils";
import { PageProps } from "@/types";
import StudentProfile from "./StudentProfile";
import MakaremLogo from "./ui/MakaremLogo";

type MainNavProps = {
    reversed?: boolean;
    reversedLinks?: boolean;
};

export default function MainNav({
    reversed = true,
    reversedLinks = false,
}: MainNavProps) {
    const {
        auth: { user },
    } = usePage<PageProps>().props;
    return (
        <>
            <div
                className={cn(
                    "max-w-screen-xl hidden gap-2 lg:flex lg:flex-row justify-between items-center mx-auto px-6",
                    reversed && "lg:flex-row-reverse"
                )}
            >
                <div>
                    <div className="flex gap-14 ">
                        <div
                            className={cn(
                                " lg:flex lg:flex-row lg:gap-2 ",
                                reversedLinks && "lg:flex-row-reverse"
                            )}
                        >
                            {publicLinks.map((link, index) => (
                                <NavbarLink
                                    href={link.url}
                                    className={`text-md  ${
                                        link.name === "links.home"
                                            ? " font-semibold text-md "
                                            : ""
                                    }`}
                                    key={index}
                                >
                                    {t(link.name as PublicLinksTranslation)}
                                </NavbarLink>
                            ))}
                            {user && (
                                <NavbarLink
                                    href="/enrollments"
                                    className={`text-md font-extrabold`}
                                >
                                    {t("links.myCourses")}
                                </NavbarLink>
                            )}
                            {user?.roles?.find(
                                (role) =>
                                    role.name === "admin" ||
                                    role.name === "super-admin"
                            ) && (
                                <NavbarLink
                                    href="/admin/dashboard"
                                    className="text-md"
                                >
                                    {t("links.adminPanel")}
                                </NavbarLink>
                            )}
                        </div>
                        {user ? (
                            <StudentProfile user={user} role="public" />
                        ) : (
                            <div className="flex gap-4 text-md">
                                <Button
                                    asChild
                                    variant={"gradientBlue"}
                                    className="w-32 h-10 "
                                >
                                    <Link href={"/register"}>
                                        {" "}
                                        {t("app.register")}
                                    </Link>
                                </Button>
                                <Button
                                    asChild
                                    variant={"outline"}
                                    className="w-32 h-10 text-PrimaryBlue"
                                >
                                    <Link href={"/login"}>
                                        {" "}
                                        {t("app.login")}
                                    </Link>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <Link href={"/"}>
                        <SmallLogo />
                    </Link>
                    <MakaremLogo />
                </div>
            </div>
        </>
    );
}
