import { PropsWithChildren, useEffect } from "react";
import SiteHeader from "@/Components/SiteHeader";
import Footer from "@/Components/Footer";
import { usePage } from "@inertiajs/react";
import { PageProps } from "@/types";
import { toast } from "sonner";

export default function DefaultLayout({ children }: PropsWithChildren) {
    const { flash } = usePage<PageProps>().props;

    // Use useEffect to display flash messages only once
    useEffect(() => {
        if (flash.error) {
            toast.error(flash.error);
            flash.error = undefined;
        }
        if (flash.success) {
            toast.success(flash.success);
            flash.success = undefined;
        }
    }, [flash.error, flash.success]); // Dependency array ensures effect runs only when flash messages change

    return (
        <>
            <SiteHeader />
            <div className="flex flex-col items-center min-h-screen pt-6 sm:pt-0 ">
                <div className="w-full max-w-screen-xl px-4 py-2 mx-auto mt-6 sm:rounded-lg">
                    {children}
                </div>
            </div>
            <Footer />
        </>
    );
}
